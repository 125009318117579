
import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";

type Request = {
  id: number;
  status: {
    name: string;
    color: string;
  };
};

@Component({
  filters: {
    date(value) {
      if (!value) return;

      return moment(value).format("Do MMMM YYYY");
    }
  }
})
export default class RequestComponent extends Vue {
  @Prop(Object) readonly request!: Request;
}
